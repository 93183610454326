const queryKeys = {
  showSideNav: 'show-side-nav',
  filterOrder: 'filter-order',
  orders: 'orders',
  orderId: 'order-id',
  orderDetail: 'order-detail',
  filterArrival: 'filter-arrival',
  arrivals: 'arrivals',
  arrivalId: 'arrival-id',
  arrivalDetail: 'arrival-detail',
  isLoadingFlams: 'is-loading-flams',
  filterSupplier: 'filter-supplier',
  suppliers: 'suppliers',
  stores: 'stores',
  filterStore: 'filter-store',
  filterMappingSmaregy: 'filter-mapping-smaregy',
  mappingSmaregy: 'mapping-smaregy',
  filterMappingFinet: 'filter-mapping-finet',
  mappingFinet: 'mapping-finet',
  mappingArrivalRecordId: 'mapping-arrival-record-id',
  detailMappingArrivalRecord: 'detail-mapping-arrival-record',
  mappingOrderId: 'mapping-order-id',
  detailMappingOrder: 'detail-mapping-order',
  filterSystemEmail: 'filter-system-email',
  systemEmails: 'system-emails',
  systemEmailId: 'system-email-id',
  systemEmailDetail: 'system-email-detail',
  filterAccount: 'filter-account',
  accounts: 'accounts',
  listStoreAccounts: 'list-store-accounts',
  listStoreAccountsDetail: 'list-store-accounts-detail',
  batchSettings: 'batch-settings',
  filterLoggerBatchResult: 'filter-logger-batch-result',
  loggerBatchResults: 'logger-batch-result',
  filterPlaceOrderFLam: 'filter-place-order-fLam',
  placeOrderFLam: 'place-order-fLam',
  filterPlaceOrderFinet: 'filter-place-order-finet',
  placeOrderFinet: 'place-order-finet',
  filterArricalToSmaregi: 'filter-arrical-to-smaregi',
  arricalToSmaregi: 'arrical-to-smaregi',
  profile: 'profile',
} as const;

export default queryKeys;
