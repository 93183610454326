import { useQuery, useQueryClient } from '@tanstack/react-query';
import { KeyLoadingDownloadFlam } from 'constants/enum';
import queryKeys from 'constants/queryKeys';
import { useCallback } from 'react';
interface IData {
  [key: string]: boolean;
}

const initialData: IData = {
  [KeyLoadingDownloadFlam.ORDER_FLAM]: false,
  [KeyLoadingDownloadFlam.ARRIVAL_FLAM]: false,
  [KeyLoadingDownloadFlam.ARRIVAL_SEND_SMAREGI]: false,
  [KeyLoadingDownloadFlam.SUPPLER]: false,
  [KeyLoadingDownloadFlam.IMPORT_STORE]: false,
  [KeyLoadingDownloadFlam.IMPORT_ORDER]: false,
  [KeyLoadingDownloadFlam.IMPORT_ARRIVAL]: false,
  [KeyLoadingDownloadFlam.UPLOAD_FILE_ORDER]: false,
  [KeyLoadingDownloadFlam.UPLOAD_FILE_ARRIVAL]: false,
  [KeyLoadingDownloadFlam.UPLOAD_FILE_STORE]: false,
};

export default function useIsLoadingFlam(enabled = false) {
  const queryClient = useQueryClient();
  const { data: isLoadingFlam } = useQuery<IData>({
    queryKey: [queryKeys.isLoadingFlams],
    enabled,
    initialData,
  });
  const setIsLoadingFlam = useCallback(
    (key: string, isLoading: boolean) => {
      queryClient.setQueriesData({ queryKey: [queryKeys.isLoadingFlams] }, (prevData: IData | undefined) => ({
        ...prevData,
        [key]: isLoading,
      }));
    },
    [queryClient],
  );

  return { isLoadingFlam, setIsLoadingFlam };
}
