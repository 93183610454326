export enum Direction {
  Up = 1,
  Down,
  Left,
  Right,
}

export enum StepForgotPassword {
  EnterEmail = 1,
  DoneEmail = 2,
}
export enum Size {
  ActionSmall = 'action-small',
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  FullWidth = 'full-width',
}
export enum ActionChangePage {
  PREV = 1,
  NEXT = 2,
}
export enum FilterOrder {
  STATE = 'state',
}
export enum FilterArrival {
  STATE = 'state',
}
export enum FilterAccount {
  STATUS = 'status',
  STORE_ID = 'storeId',
}
export enum FilterLoggerBatchResult {
  TYPE = 'type',
  STATE = 'state',
}
export enum PlaceOrderState {
  ACTIVE = 1,
  INACTIVE = 0,
  PENDING = 2,
  ERROR = 3,
}
export enum ArrivalState {
  ACTIVE = 1,
  INACTIVE = 0,
  PENDING = 2,
  ERROR = 3,
}
export enum KeyLoadingDownloadFlam {
  ORDER_FLAM = 'ORDER_FLAM',
  ARRIVAL_FLAM = 'ARRIVAL_FLAM',
  ARRIVAL_SEND_SMAREGI = 'ARRIVAL_SEND_SMAREGI',
  SUPPLER = 'SUPPLER',
  IMPORT_STORE = 'IMPORT_STORE',
  IMPORT_ORDER = 'IMPORT_ORDER',
  IMPORT_ARRIVAL = 'IMPORT_ARRIVAL',
  ODER_SEND_FINET = 'ODER_SEND_FINET',
  UPLOAD_FILE_ORDER = 'UPLOAD_FILE_ORDER',
  UPLOAD_FILE_ARRIVAL = 'UPLOAD_FILE_ARRIVAL',
  UPLOAD_FILE_STORE = 'UPLOAD_FILE_STORE',
}
export enum MappingFieldTargetType {
  SMAREGY = 1,
  FINET = 2,
}
export enum MappingType {
  FIELD = 1,
  DATA = 2,
}
export enum CommonStatus {
  ACTIVE = 1,
  INACTIVE = 0,
}
export enum LogState {
  SUCCESS = 1,
  ERROR = 0,
}
export enum LogType {
  PLACE_ORDER = 1,
  ARRIVAL = 2,
  SUPPLIER = 3,
  STORE = 4,
  BATCHING_RUN = 5,
  SMAREGI = 6,
  FINET = 7,
}

export enum FormValidationType {
  PLACE_ORDER_FROM_FLAM = 1,
  PLACE_ORDER_TO_FINET = 2,
  ARRIVAL_TO_SMAREGI = 3,
}
