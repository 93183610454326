import Account from './Account.svg';
import Add from './Add.svg';
import ArrowDown from './Arrow-down.svg';
import ArrowDropDown from './Arrow-drop-down.svg';
import ArrowLeft from './Arrow-left.svg';
import ArrowRight from './Arrow-right.svg';
import ArrowRightBig from './Arrow-right-big.svg';
import BxList from './Bx-list.svg';
import Calendar from './Calendar.svg';
import ChevronLeft from './Chevron-left.svg';
import ChevronRight from './Chevron-right.svg';
import DataMapping from './Data-mapping.svg';
import Download from './Download.svg';
import Edit from './Edit.svg';
import ErrorMessage from './Error-message.svg';
import Export from './Export.svg';
import EyeSlash from './Eye-slash.svg';
import Eye from './Eye.svg';
import Fluent from './Fluent.svg';
import Import from './Import.svg';
import Logo from './Logo.svg';
import MasterData from './Master-data.svg';
import Menu from './Menu.svg';
import Order from './Order.svg';
import Refresh from './Refresh.svg';
import Search from './Search.svg';
import SendBackWhite from './Send-back-white.svg';
import SendBack from './Send-back.svg';
import TrashWhite from './Trash-white.svg';
import Trash from './Trash.svg';
import View from './View.svg';
import CloseCircleOutlined from './Close-circle-outlined.svg';
import InfoCircle from './Info-circle.svg';
import ImportMapping from './Import-mapping.svg';
import ExportMapping from './Export-mapping.svg';
import TrashMapping from './Trash-mapping.svg';
import AddMapping from './Add-mapping.svg';
import ImportWhite from './Import-white.svg';

const icons = {
  Logo,
  EyeSlash,
  Eye,
  Menu,
  ArrowLeft,
  ArrowRight,
  Order,
  Search,
  Calendar,
  ArrowDown,
  View,
  Trash,
  SendBack,
  ArrowDropDown,
  ChevronLeft,
  ChevronRight,
  BxList,
  DataMapping,
  Fluent,
  MasterData,
  Account,
  ErrorMessage,
  Edit,
  Export,
  Add,
  Import,
  Refresh,
  Download,
  TrashWhite,
  SendBackWhite,
  ArrowRightBig,
  CloseCircleOutlined,
  InfoCircle,
  ImportMapping,
  ExportMapping,
  TrashMapping,
  AddMapping,
  ImportWhite,
};

export default icons;
