import { sendPost } from './axios';

export interface IPayloadLogin {
  email: string;
  password: string;
}
export interface ILoginResponse {
  accessToken: string;
  refreshToken: string;
}
export interface IPayloadForgotPassword {
  email: string;
}
export interface IForgotPasswordResponse {
  message: string;
}
export interface IPayloadConfirmForgotPassword {
  newPassword: string;
  confirmPassword: string;
  inviteCode: string;
}
export interface IPayloadChangePassword {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}
export const loginApi = (payload: IPayloadLogin): Promise<ILoginResponse> => sendPost('auth/login', payload);

export const reqForgotPassword = (payload: IPayloadForgotPassword): Promise<any> =>
  sendPost('/auth/forgot-password', payload);
export const confirmForgotPassword = (payload: IPayloadConfirmForgotPassword): Promise<any> =>
  sendPost('/auth/reset-password', payload);
export const changePassword = (payload: IPayloadChangePassword): Promise<any> =>
  sendPost('/auth/change-password', payload);
