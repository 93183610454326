import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button, Menu } from 'antd';
import classNames from 'classnames';
import { HomeOutlined, TableOutlined, SettingOutlined } from '@ant-design/icons';
import styles from './styles.module.scss';
import useToggleSideNav from 'utils/hooks/useToggleSideNav';
import icons from 'assets/icons';
import { useTranslation } from 'react-i18next';
import routePaths from 'constants/routePaths';
import useIsLoadingFlam from 'utils/hooks/useIsLoadingFlam';
import { KeyLoadingDownloadFlam } from 'constants/enum';
import Loader from 'components/Loader';

const { SubMenu } = Menu;

export default function SideNav() {
  const { t } = useTranslation();
  const { collapsed, toggleSideNav } = useToggleSideNav();
  const location = useLocation();
  const [selectedKey, setSelectedKey] = useState('1');
  const { isLoadingFlam, setIsLoadingFlam } = useIsLoadingFlam();

  const handleToggleClick = () => {
    // Gọi hàm toggleSideNav để thay đổi giá trị của collapsed
    toggleSideNav();
  };
  const routes = [
    {
      key: '1',
      text: t('order.textNav'),
      url: routePaths.orderList,
      icon: <img className={styles.iconNav} src={icons.Order} alt="Order nav" />,
    },
    {
      key: '2',
      text: t('arrivalRecord.textNav'),
      url: routePaths.arrivalRecord,
      icon: <img className={styles.iconNav} src={icons.BxList} alt="arrival Record" />,
    },
    {
      key: '3',
      text: t('dataMapping.textNav'),
      icon: <img className={styles.iconNav} src={icons.DataMapping} alt="Data Mapping" />,
      children: [
        {
          key: '3.1',

          text: (
            <span style={{ margin: '0px' }}>
              {t('dataMapping.textNavFlamFinetFisrt')}
              <br />
              {t('dataMapping.textNavFlamFinetfTwo')}
            </span>
          ),
          url: routePaths.dataMappingFlamFinet,
        },
        {
          key: '3.2',
          text: (
            <span>
              {t('dataMapping.textNavFlamFisrt')}
              <br />
              {t('dataMapping.textNavFlamTwo')}
            </span>
          ),
          url: routePaths.dataMappingFlam,
        },
      ],
    },
    {
      key: '4',
      text: t('log.textNav'),
      icon: <img className={styles.iconNav} src={icons.Fluent} alt="Fluent" />,
      children: [
        {
          key: '4.1',
          text: t('log.textNavBatchResult'),
          url: routePaths.logBatchResult,
        },
        {
          key: '4.2',
          text: t('log.textNavBatchSettings'),
          url: routePaths.logBatchSettings,
        },
      ],
    },
    {
      key: '5',
      text: t('masterData.textNav'),
      icon: <img className={styles.iconNav} src={icons.MasterData} alt="Fluent" />,
      children: [
        {
          key: '5.1',
          text: (
            <span>
              {t('masterData.textNavEDISuppliersFirst')}
              <br />
              {t('masterData.textNavEDISuppliersTwo')}
            </span>
          ),
          url: routePaths.masterDataEDISuppliers,
        },
        {
          key: '5.2',
          text: <span>{t('masterData.textNavEDIStore')}</span>,
          url: routePaths.masterDataEDIStores,
        },
        {
          key: '5.3',
          text: (
            <span>
              {t('masterData.textNavPlaceOderFlamFirst')}
              <br />
              {t('masterData.textNavPlaceOderFlamTwo')}
            </span>
          ),
          url: routePaths.masterDataPlaceOrderFlam,
        },
        {
          key: '5.4',
          text: (
            <span>
              {t('masterData.textNavOrderUploadFormatFirst')}
              <br />
              {t('masterData.textNavOrderUploadFormatTwo')}
            </span>
          ),
          url: routePaths.masterDataOrderUploadFormat,
        },
        {
          key: '5.5 ',
          text: (
            <span>
              {t('masterData.textNavArrivalRegistrationFormatFirst')}
              <br />
              {t('masterData.textNavArrivalRegistrationFormatTwo')}
            </span>
          ),
          url: routePaths.masterDataArrivalRegistrationFormat,
        },
      ],
    },
    {
      key: '6',
      text: t('account.textNav'),
      url: routePaths.account,
      icon: <img className={styles.iconNav} src={icons.Account} alt="Account" />,
    },
    {
      key: '7',
      text: t('errorEmailSettings.textNav'),
      url: routePaths.errorEmailSettings,
      icon: <img className={styles.iconNav} src={icons.ErrorMessage} alt="Error Message" />,
    },
  ];

  useEffect(() => {
    routes.forEach((route) => {
      if (location.pathname.startsWith(route.url || '###')) {
        setSelectedKey(route.key);
      }
      if (route.children) {
        route.children.forEach((childRoute) => {
          if (location.pathname.startsWith(childRoute.url || '###')) {
            setSelectedKey(childRoute.key);
          }
        });
      }
    });
  }, [location.pathname]);

  const isLoading =
    isLoadingFlam[KeyLoadingDownloadFlam.UPLOAD_FILE_ORDER] ||
    isLoadingFlam[KeyLoadingDownloadFlam.UPLOAD_FILE_ARRIVAL] ||
    isLoadingFlam[KeyLoadingDownloadFlam.UPLOAD_FILE_STORE];
  return (
    <>
      {isLoading && <Loader />}
      <div
        className={classNames({
          [styles.sideNav]: true,
          [styles.sideNavCollapsed]: collapsed,
        })}
        style={{ width: collapsed ? 80 : 250, transition: 'width 0.3s' }}
      >
        <Menu
          selectedKeys={[selectedKey]}
          defaultOpenKeys={[]}
          mode="inline"
          inlineCollapsed={collapsed}
          className={styles.wrapperMenuItem}
        >
          {routes.map((route) => {
            if (route.children) {
              return (
                <SubMenu key={route.key} icon={route.icon} title={route.text}>
                  {route.children?.map((childRoute) => (
                    <Menu.Item key={childRoute.key} title={childRoute.text}>
                      <Link to={childRoute.url}>{childRoute.text}</Link>
                    </Menu.Item>
                  ))}
                </SubMenu>
              );
            }
            return (
              <Menu.Item key={route.key} icon={route.icon}>
                <Link to={route.url}>{route.text}</Link>
              </Menu.Item>
            );
          })}
        </Menu>
        <div className={styles.handleToggleClick} onClick={handleToggleClick}>
          <img className={styles.logoWrapper} src={collapsed ? icons.ArrowRight : icons.ArrowLeft} alt="Logo" />
        </div>
      </div>
    </>
  );
}
