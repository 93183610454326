import { getMasterData } from "api/masterData";
import { useQuery } from "@tanstack/react-query";

export default function useMasterData(enabled = false) {
  const { data: masterData, refetch: refetchMasterData } = useQuery<any>({
    queryKey: ["masterData"],
    enabled,
    queryFn: getMasterData,
  });
  return { masterData, refetchMasterData };
}
