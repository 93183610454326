import { notification } from 'antd';
export interface INoticeProps {
  type: 'success' | 'info' | 'warning' | 'error';
  message: string;
  description: string;
}
const CustomNotification = ({ type, message, description }: INoticeProps) => {
  notification.destroy();
  return notification[type]({
    message,
    description,
  });
};

export default CustomNotification;
