import { useQuery, useQueryClient } from "@tanstack/react-query";
import queryKeys from "constants/queryKeys";
import { useCallback } from "react";

export default function useToggleSideNav() {
  const queryClient = useQueryClient();
  const { data: collapsed } = useQuery({
    queryKey: [queryKeys.showSideNav],
    enabled: false,
    initialData: { pageSize: 10, pageIndex: 1 },
  });
  const toggleSideNav = useCallback(() => {
    queryClient.setQueriesData(
      { queryKey: [queryKeys.showSideNav] },
      (data: any) => !data
    );
  }, [queryClient]);
  return { collapsed: !Boolean(collapsed), toggleSideNav };
}
