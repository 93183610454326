import moment from 'moment';

export const padTwo = (index: number) => {
  if (index !== 0) {
    return index.toString().padStart(2, '0');
  }
  return index;
};
export const handlePooledDataString = (first: any, second: any) => {
  if (first && second) return `${first} - ${second}`;

  return first || second;
};
export const REGEX_PASSWORD = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[_@./!@#$%^*(),~`'":;\\\?}\<\>\]\{[&+-]).{6,}$/;
export const REGEX_NUMBER_LATIN = /^[a-zA-Z0-9]+$/;
// &+-{}<>,?\\;:"'~`
export const PageDefault = 1;
export const PageSizeDefault = 10;
export const momentDateFormat = (date: string) => moment(date).format('YYYY/MM/DD');
export const formatNumberOtPrice = (
  amount?: number | string,
  isSeparator = true,
  isDefault = false,
  separator: string = ',',
) => {
  if (!amount && amount !== 0 && amount !== '0') {
    return isDefault ? 0 : undefined;
  }
  if (!isSeparator) {
    return Number(amount);
  }

  return Number(amount).toLocaleString('en-US').replace(/,/g, separator);
};
export const MAX_SIZE_FILE = 10;
export const validateIsNotEmptyString = (value: any, text: string) => {
  if (value && value.trim() === '') {
    return Promise.reject(text);
  }
  return Promise.resolve();
};
