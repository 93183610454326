import PageHeader from 'components/PageHeader';
import SideNav from 'components/SideNav';
import Cookies from 'js-cookie';
import classNames from 'classnames';
import { Navigate, Outlet } from 'react-router-dom';
import useMasterData from 'utils/hooks/useMasterData';
import useToggleSideNav from 'utils/hooks/useToggleSideNav';
import styles from './styles.module.scss';
import useProfile from 'utils/hooks/useProfile';

export default function PageWrapper() {
  useMasterData(true);
  useProfile(true);
  const { collapsed } = useToggleSideNav();
  const isAuthenticated = !!Cookies.get('token');
  if (!isAuthenticated) return <Navigate to="/login" />;

  return (
    <>
      <PageHeader />
      <div className={styles.pageWrapper}>
        <div className={styles.mainWrapper}>
          <SideNav />
          <div
            className={classNames({
              [styles.pageContent]: true,
              [styles.pageContentCollapsed]: collapsed,
            })}
          >
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
}
