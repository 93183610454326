import { Button, Form, Modal, ModalProps } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import classNames from 'classnames';
import CommonInput from 'components/CommonInput';
import { ISystemEmail } from 'pages/SystemEmail/SystemEmailList';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { REGEX_PASSWORD } from 'utils/helper';
interface IProps extends ModalProps {
  handleCancel: () => void;
  handleLogicOk: (values: any) => void;
  open: boolean;
  confirmLoading: boolean;
}

export default function ModalChangePassword({ handleCancel, handleLogicOk, open, confirmLoading, ...rest }: IProps) {
  const { t } = useTranslation();
  const [form] = useForm();

  useEffect(() => {
    form.resetFields();
  }, [open]);

  const validatePasswordConfirm = ({ getFieldValue }: any) => ({
    validator(data: any, value: string) {
      if (!REGEX_PASSWORD.test(value)) {
        return Promise.reject(t('validate.errorFormatPassword'));
      }
      if (getFieldValue('newPassword') !== value) {
        return Promise.reject(t('validate.confirmNewPassword'));
      }
      return Promise.resolve();
    },
  });

  return (
    <>
      <Modal
        {...rest}
        title={t('changePassword.title')}
        className={styles.wrapperModal}
        onCancel={handleCancel}
        open={open}
      >
        <div className={styles.wrapperForm}>
          <Form onFinish={handleLogicOk} form={form}>
            <Form.Item
              label={t('changePassword.oldPassword')}
              name="oldPassword"
              rules={[
                { required: true, message: t('changePassword.oldPasswordRequired') },
                {
                  pattern: REGEX_PASSWORD,
                  message: t('validate.errorFormatPassword'),
                },
              ]}
              labelAlign="left"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              className={classNames(styles.formItemLabel, styles.itemInput)}
              validateFirst
            >
              <CommonInput isPassword={true} placeholder={t('changePassword.placeholderPassword')} />
            </Form.Item>
            <Form.Item
              label={t('changePassword.newPassword')}
              name="newPassword"
              rules={[
                { required: true, message: t('changePassword.newPasswordRequired') },
                {
                  pattern: REGEX_PASSWORD,
                  message: t('validate.errorFormatPassword'),
                },
              ]}
              labelAlign="left"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              className={classNames(styles.formItemLabel, styles.itemInput)}
              validateFirst
            >
              <CommonInput isPassword={true} placeholder={t('changePassword.placeholderPassword')} />
            </Form.Item>
            <Form.Item
              label={t('changePassword.confirmPassword')}
              name="confirmPassword"
              rules={[
                { required: true, message: t('changePassword.confirmPasswordRequired') },
                validatePasswordConfirm,
              ]}
              labelAlign="left"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              className={classNames(styles.formItemLabel, styles.itemInput)}
              validateFirst
            >
              <CommonInput isPassword={true} placeholder={t('changePassword.placeholderPassword')} />
            </Form.Item>

            <Form.Item wrapperCol={{ span: 24 }} className={styles.wrapperButton}>
              <Button htmlType="button" className="buttonCancel" onClick={handleCancel}>
                {t('changePassword.cancel').toUpperCase()}
              </Button>
              <Button htmlType="submit" className="buttonOk" loading={confirmLoading}>
                {t('changePassword.ok').toUpperCase()}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  );
}
