import loadable from '@loadable/component';
import routePaths from 'constants/routePaths';
import { Route, Routes } from 'react-router-dom';
import AuthWrapper from 'wrappers/AuthWrapper';

const Login = loadable(() => import('pages/Login'));
const ArrivalRecord = loadable(() => import('pages/ArrivalRecord'));
const ConfirmForgotPassword = loadable(() => import('pages/ConfirmForgotPassword'));
const ForgotPassword = loadable(() => import('pages/ForgotPassword'));
const Orders = loadable(() => import('pages/Order'));
const Tasks = loadable(() => import('pages/Tasks'));
const MappingArrivalRecord = loadable(() => import('pages/DataMapping/MappingArrivalRecord'));

const LoggerBatchSetting = loadable(() => import('pages/Logger/BatchSetting'));
const MasterDataSupplier = loadable(() => import('pages/MasterData/Supplier'));
const MasterDataStore = loadable(() => import('pages/MasterData/Store'));
const MasterDataPlaceOrderFlam = loadable(() => import('pages/MasterData/PlaceOrderFlam'));
const MasterDataPlaceOrderFinet = loadable(() => import('pages/MasterData/PlaceOrderFinet'));
const MasterDataArrivalToSmaregi = loadable(() => import('pages/MasterData/ArrivalToSmaregi'));
const MappingOrder = loadable(() => import('pages/DataMapping/MappingOrder'));
const LoggerBatchResult = loadable(() => import('pages/Logger/BatchResult'));
const Account = loadable(() => import('pages/Account'));
const SystemEmail = loadable(() => import('pages/SystemEmail'));

export default function AppWrapper() {
  return (
    <div className="root-wrapper">
      <Routes>
        <Route path={routePaths.login} element={<Login />} />
        <Route path={routePaths.forgotPassword} element={<ForgotPassword />} />
        <Route path={routePaths.confirmForgotPassword} element={<ConfirmForgotPassword />} />
        <Route path="/" element={<AuthWrapper />}>
          <Route path={'/'} element={<Orders />} />
          <Route path={routePaths.orderList} element={<Orders />} />
          <Route path={routePaths.arrivalRecord} element={<ArrivalRecord />} />
          <Route path={routePaths.dataMappingFlamFinet} element={<MappingOrder />} />
          <Route path={routePaths.dataMappingFlam} element={<MappingArrivalRecord />} />
          <Route path={routePaths.logBatchResult} element={<LoggerBatchResult />} />
          <Route path={routePaths.logBatchSettings} element={<LoggerBatchSetting />} />
          <Route path={routePaths.masterDataEDISuppliers} element={<MasterDataSupplier />} />
          <Route path={routePaths.masterDataEDIStores} element={<MasterDataStore />} />
          <Route path={routePaths.masterDataPlaceOrderFlam} element={<MasterDataPlaceOrderFlam />} />
          <Route path={routePaths.masterDataOrderUploadFormat} element={<MasterDataPlaceOrderFinet />} />
          <Route path={routePaths.masterDataArrivalRegistrationFormat} element={<MasterDataArrivalToSmaregi />} />
          <Route path={routePaths.account} element={<Account />} />
          <Route path={routePaths.errorEmailSettings} element={<SystemEmail />} />
          <Route path="/tasks" element={<Tasks />} />
        </Route>
      </Routes>
    </div>
  );
}
