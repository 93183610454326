import { Input, InputProps } from 'antd';
import classnames from 'classnames';
import styles from './styles.module.scss';
import icons from 'assets/icons';
export interface IProps extends InputProps {
  isPassword?: boolean;
  className?: string;
}
const CommonInput = (props: IProps) => {
  const { isPassword = false, className, maxLength = 500, ...rest } = props;

  return isPassword ? (
    // <div className={styles.inputWrapper}>
    <Input.Password
      className={classnames(styles.input, className)}
      iconRender={(visible) => {
        return visible ? (
          <img className={styles.iconEyePass} src={icons.Eye} alt="eye-icon"></img>
        ) : (
          <img src={icons.EyeSlash} alt="eye-icon"></img>
        );
      }}
      {...rest}
      maxLength={maxLength}
    />
  ) : (
    // </div>
    // <div className={styles.inputWrapper}>
    <Input className={classnames(styles.input, className)} maxLength={maxLength} {...rest} />
    // </div>
  );
};
export default CommonInput;
