import type { MenuProps } from 'antd';
import { Dropdown } from 'antd';
import icons from 'assets/icons';
import { useTranslation } from 'react-i18next';
import { logout } from 'utils/helper/authentication';
import useToggleSideNav from 'utils/hooks/useToggleSideNav';
import styles from './styles.module.scss';
import { useState } from 'react';
import ModalChangePassword from 'components/ModalChangePassword';
import { handleErrorMessage, handleSuccessMessage } from 'i18n';
import { changePassword } from 'api/authentication';
import useProfile from 'utils/hooks/useProfile';

export default function PageHeader() {
  const { t } = useTranslation();
  // const { profile } = useProfile();
  // const { toggleSideNav } = useToggleSideNav();

  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const { profile } = useProfile();

  const showModal = () => {
    setOpen(true);
  };
  const handleOkModalDetail = () => {
    setOpen(false);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  const handleChangePassword = async (values: any) => {
    try {
      setConfirmLoading(true);
      await changePassword(values);
      handleSuccessMessage(t('message.changePasswordSuccess'));
    } catch (error) {
      handleErrorMessage(error);
    }
    setConfirmLoading(false);
    handleCancel();
  };

  const keyMenu = {
    changePassword: {
      label: t('common.changePassword'),
      key: '0',
    },
    logout: {
      label: t('common.logout'),
      key: '1',
    },
  };
  const items: MenuProps['items'] = Object.values(keyMenu);
  const handleMenuClick: MenuProps['onClick'] = (e) => {
    if (e.key === keyMenu.logout.key) {
      logout();
    }
    if (e.key === keyMenu.changePassword.key) {
      showModal();
    }
  };

  return (
    <>
      <div className={styles.headerWrapper}>
        <img className={styles.logoWrapper} src={icons.Logo} alt="Logo" />
        <div className={styles.menuWrapper}>
          <div className={styles.textItem}>
            <p>{profile?.name}</p>
          </div>
          <div className={styles.menuItem}>
            <Dropdown
              menu={{
                items,
                onClick: handleMenuClick,
              }}
              trigger={['click']}
            >
              <div>
                <img src={icons.Menu} alt="Menu" />
              </div>
            </Dropdown>
          </div>
        </div>
      </div>

      <ModalChangePassword
        open={open}
        onOk={handleOkModalDetail}
        confirmLoading={confirmLoading}
        footer={null}
        handleCancel={handleCancel}
        handleLogicOk={handleChangePassword}
      />
    </>
  );
}
