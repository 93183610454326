import { message } from 'antd';
import configs from '../constants/config';
import CustomNotification from 'components/CustomNotification';

export const handleErrorMessage = (error: any) => {
  message.destroy();
  // message.error(getErrorMessage(error));
  handleErrorMessageFe(getErrorMessage(error))
  if (configs.APP_ENV !== 'prod') {
    // tslint:disable-next-line: no-console
    console.log(error);
  }
};

export const getErrorMessage = (error: any) => {
  return error?.response?.data?.errorMessage || 'Something went wrong!';
};
export const handleSuccessMessage = (mes: string) => {
  CustomNotification({
    type: 'success',
    message: mes,
    description: '',
  });
};
export const handleErrorMessageFe = (mes: string) => {
  CustomNotification({
    type: 'error',
    message: mes,
    description: '',
  });
};
