const routePaths = {
  login: '/login',
  forgotPassword: '/forgot-password',
  confirmForgotPassword: '/confirm-forgot-password',
  orderList: '/orders',
  arrivalRecord: '/arrival-record',
  dataMappingFlamFinet: '/data-mapping-finet-flam',
  dataMappingFlam: '/data-mapping-flam',
  logBatchResult: '/log-batch-result',
  logBatchSettings: '/log-batch-settings',
  masterDataEDISuppliers: '/master-data-edi-suppliers',
  masterDataEDIStores: '/master-data-edi-stores',
  masterDataPlaceOrderFlam: '/master-data-place-oder-flam',
  masterDataOrderUploadFormat: '/master-data-order-upload-format',
  masterDataArrivalRegistrationFormat: '/master-data-arrival-registration-format',
  account: '/account',
  errorEmailSettings: '/error-email-settings',
} as const;

export default routePaths;
