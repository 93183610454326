import { getProfile } from 'api/profile';
import { useQuery } from '@tanstack/react-query';
import queryKeys from 'constants/queryKeys';

export default function useProfile(enabled = false) {
  const { data: profile, refetch: refetchProfile } = useQuery<any>({
    queryKey: [queryKeys.profile],
    enabled,
    queryFn: getProfile,
  });
  return { profile, refetchProfile };
}
