import Cookies from "js-cookie";
import { history } from "../../App";

// export const login = async (payload: any) => {
//   const params = _.pick(payload, ["email", "password"]);
//   try {
//     const data = await signin(params);
//     const { token, refreshToken } = data.data;
//     Cookies.set("token", token, {
//       expires: payload.rememberMe ? 999999 : undefined,
//     });
//     Cookies.set("refreshToken", refreshToken, {
//       expires: payload.rememberMe ? 999999 : undefined,
//     });
//     history.push("/");
//   } catch (error) {
//     handleErrorMessage(error);
//   }
// };

export const logout = () => {
  Cookies.remove("token");
  Cookies.remove("refreshToken");
  history.push("/login");
};
